import React from "react";

const Landing = () => {
  return (
    <div>
    </div>
  )
}

export default Landing;
