import React from 'react';
import { SRLWrapper } from "simple-react-lightbox";

import Image from "./Image";

const options ={
  settings: {
    overlayColor: "rgba(255, 255, 255, 0.9)",
  },
  buttons: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    iconColor: "rgb(0, 0, 0)",
    size: "60px",
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false,
  },
  caption: {
    showCaption: false
  },
  thumbnails: {
    showThumbnails: false,
  }
}

const Lightbox = ({ images }) => {
  return (
    <SRLWrapper options={options}>
      <div className={"gallery-container"}>
        { images.map ( (image, i) => {
          return (
            <Image
              key={image}
              src={image}
              alt={`farts & craft ${i + 1}`}
              className={"gallery-image"}
            />
          )
        })}
      </div>
    </SRLWrapper>
  )
};

export default Lightbox;
