import React from "react";
import { Lightbox } from '../components';

const GoodThings = () => {
  const goodImages = [
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298569/Goodthings/GT147-WEB_s7x6gm.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298569/Goodthings/GT158-WEB_gbyeia.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298568/Goodthings/GT157-WEB_melvkn.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298568/Goodthings/GT141-WEB_xpj7y1.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298568/Goodthings/GT152-WEB_vanxxr.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298568/Goodthings/GT149-WEB_fbvqwb.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298567/Goodthings/GT72-WEB_ihi2pc.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298568/Goodthings/GT145-WEB_j1irc9.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298567/Goodthings/GT101-WEB_sjp6ni.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298567/Goodthings/GT144-WEB_tkk5ch.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298567/Goodthings/GT139-WEB_qsmctc.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298567/Goodthings/GT96-WEB_h95edw.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298567/Goodthings/GT130-WEB_zdvpq7.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298567/Goodthings/GT70-WEB_j5yxjv.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298567/Goodthings/GT105-WEB_hkywrt.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298567/Goodthings/GT129-WEB_pmhpxk.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298566/Goodthings/GT55-WEB_sv0o9w.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298566/Goodthings/GT97-WEB_ovxrwb.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298566/Goodthings/GT71-WEB_hz4bga.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298566/Goodthings/GT66-WEB_ghtycy.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298565/Goodthings/GT63-WEB_zc2vmt.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298565/Goodthings/GT38-WEB_fngjk6.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298565/Goodthings/GT75-WEB_revmaz.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298565/Goodthings/GT10-WEB_qfdtgk.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298565/Goodthings/GT12-WEB_n03mut.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298564/Goodthings/GT74-WEB_cgmyhu.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298565/Goodthings/GT30-WEB_ubb09j.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298564/Goodthings/GT21-WEB_rysopk.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298564/Goodthings/GT24-WEB_qvg2hr.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298564/Goodthings/GT28-WEB_hwbf3l.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648298564/Goodthings/GT04-WEB_vtanr2.jpg',
  ];

  return (
    <div className={"page"}>
      <Lightbox images={goodImages} />
    </div>
  )
}

export default GoodThings;
