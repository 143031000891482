import React from "react";
import { Lightbox } from '../components';

const FartsNCraft = () => {
  const fartImages = [
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648299023/Farts%20and%20Craft/FAR109-WEB_xacqgr.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648299022/Farts%20and%20Craft/FAR106-WEB_kig3ih.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648299022/Farts%20and%20Craft/FAR110-WEB_oshexi.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648299022/Farts%20and%20Craft/FAR108-WEB_ojp83y.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648299022/Farts%20and%20Craft/FAR107-WEB_xqmpsf.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648299022/Farts%20and%20Craft/FAR103-WEB_jkah4m.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648299021/Farts%20and%20Craft/FAR104-WEB_q7twvt.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648299021/Farts%20and%20Craft/FAR101-WEB_wt9eas.jpg',
    'https://res.cloudinary.com/mrwillvincent/image/upload/v1648299021/Farts%20and%20Craft/FAR102-WEB_wj0aei.jpg',
  ];

  return (
    <div className={"page"}>
      <Lightbox images={fartImages} currentImage={0}/>
    </div>
  )
}

export default FartsNCraft;
