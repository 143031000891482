import './App.css';

import {
  Routes,
  Route,
} from "react-router-dom";

import { Landing, FartsNCraft, GoodThings, About } from './pages/index';

import { Header } from './components/index';


function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/fartsncraft" element={<FartsNCraft />}/>

        <Route path="/goodthings" element={<GoodThings />}/>

        <Route path="/about" element={<About />}/>

        <Route path="/" element={<Landing />}/>
      </Routes>
    </div>
  );
}

export default App;
