import React, { useState } from 'react';

import { Link, useLocation } from "react-router-dom";

import {
  Logo,
  FartsNCraft,
  FartsNCraftSelected,
  GoodThings,
  GoodThingsSelected,
  About,
  AboutSelected
} from '../images';

import Image from './Image';

const Header = () => {
  const [navHovered, setNavHovered] = useState(false);
  const [hovered, setHovered] = useState("");
  const location = useLocation();

  return (
    <header className="app-header">
      <div>
        <Link
          to="/"
          className={"d-fit bg-white"}
          onMouseEnter={() => setNavHovered(true)}
          onMouseLeave={() => setNavHovered(false)}
        >
          <Image src={Logo} alt={"mr will vincent logo"} className={"header-logo"} />
        </Link>
        <div
          onMouseEnter={() => setNavHovered(true)}
          onMouseLeave={() => setNavHovered(false)}
          className={`nav-links bg-white ${navHovered && "show-nav-links"}`}
        >
          <Link
            to="/fartsncraft"
            onMouseEnter={() => setHovered("farts")}
            onMouseLeave={() => setHovered("")}
          >
            {hovered === "farts" || location.pathname === "/fartsncraft" ?
              <Image src={FartsNCraftSelected} alt={"text image saying Farts & Craft"} className={"nav-link-image"} />
              :
              <Image src={FartsNCraft} alt={"text image saying Farts & Craft"} className={"nav-link-image"} />
            }
          </Link>
          <Link
            to="/goodthings"
            onMouseEnter={() => setHovered("goodthings")}
            onMouseLeave={() => setHovered("")}
          >
            {hovered === "goodthings" || location.pathname === "/goodthings" ?
              <Image src={GoodThingsSelected} alt={"text image saying Good Things"} className={"nav-link-image"} />
              :
              <Image src={GoodThings} alt={"text image saying Good Things"} className={"nav-link-image"} />
            }
          </Link>
          <Link
            to="/about"
            onMouseEnter={() => setHovered("about")}
            onMouseLeave={() => setHovered("")}
          >
            {hovered === "about" || location.pathname === "/about" ?
              <Image src={AboutSelected} alt={"text image saying About"} className={"nav-link-image"} />
              :
              <Image src={About} alt={"text image saying About"} className={"nav-link-image"} />
            }
          </Link>
        </div>
      </div>
    </header>
  )
};

export default Header;
