import React from 'react';

const Paragraph = ({ text, className }) => {
  return (
    <p className={className}>
      {text}
    </p>
  )
};

export default Paragraph;
