import React from "react";
import { Image, Paragraph } from "../components";
import { WhatsThis, GetInTouch } from '../images';

const paragraph1 = "Goodthings (and now Farts & Craft) is the output of Will Vincent, a puerile creative who has trouble accepting that greetings card design is how he earns a living.";
const paragraph2 = "I used to print these off of an old Epson and sell them to the local shops, now they're in Tescos - it's bonkers. My mum rearranges the shelves to place them in direct line of sight.";

const paragraph3 = "If you want to get in touch I can be contacted at:";
const paragraph4 = "will(at) mrwillvincent(dot) co(dot) uk";
const paragraph5 = "If you're keen to make a purchase the current collection can be found over at my publisher's website - Redback cards.";

const About = () => {
  return (
    <div className={"about-page about-page-bg"}>
      <div className={"about-content-container"}>
        <Image src={WhatsThis} className={"about-page-image"} />
        <Paragraph text={paragraph1} />
        <Paragraph text={paragraph2} className={"mb-2"} />
        <Image src={GetInTouch} className={"about-page-image"} />
        <Paragraph text={paragraph3} />
        <Paragraph text={paragraph4} className={"mb-2"} />
        <Paragraph text={paragraph5} />
      </div>
    </div>
  )
}

export default About;
